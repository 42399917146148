

.lw-portal-text-input-container , .text-field-group{
    display: flex;
    flex-flow: column;
}

.lw-portal-text-input-title {
    font-family: "Sarabun";
    font-size: 15px;
    font-weight: 100;
    font-weight: 700; 
    color: #362727;
    margin-left: 5px;
   
}

.lw-portal-text-input {
  outline: none;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 300;
  background: #FFFFFF;
  color: #111111;
  border-radius: 6px;
  border: 1px solid #D3B9B9;
  padding: 10px 10px;
  width: 200px;
  height: 45px;
}

.lw-portal-text-input:focus {
    border: 1px solid #3689FF;
}

.lw-portal-text-input-info {
    font-size: 13px;
    font-family: "Roboto";
    font-weight: 400;
    color: #9b9b9b;
    margin-top: 10px;
    margin-left: 5px;
}

/* Error inputs */
.is-invalid {
    border: 1px solid rgb(255, 54, 54);
}

/*  */

.disabled {
  opacity: .45;
}

