table {
	border-collapse: column;
}

/*header text*/
th {
	font-size: 18px;
	font-weight: 400;
	color: #4A4A4A;
}

/*inside text*/
td {
	font-size: 16px;
	font-weight: 300;
	/*color: #666666;*/
}

th, td {
  text-align: left;
}

tr:nth-child(odd) {
	background: rgba(149,57,57,0.10);
}

.table {
  // display: flex;
  // flex-flow: column;
  border-radius: 10px;
  overflow: hidden;
  perspective: 1px;
  border-collapse: collapse;
  table-layout: fixed;
   width: 100%;
    white-space: nowrap;

  margin-top: 32px;
  background: #FFFFFF;
  box-shadow: 0 0 20px -10px rgba(115,115,115,0.50);
}

.table thead th {
  border-bottom: none;
}

.table td {
  border: none;
  font-family: 'Roboto';
  font-weight: 200;
  text-align: left;
  white-space: nowrap;
  // overflow: hidden;
  text-overflow: ellipsis;
  
  padding: 15px 20px 10px 20px;
    width: 100%;
}


/*workflow table - header*/
.table th {
  font-family: 'Roboto';
  font-weight: 400;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  text-align: left;
  background-color: #fff;
  border: 0;

  width: 100%;
}

.table thead tr {
  display: flex;
  flex-flow: row;
  width: 100%;
}




.table th:last-child, .table td:last-child {
  text-align: right;
  padding-right: 20px;
}


.table tbody {
max-height: calc(55vh - 225px);
display: block;
overflow-y: auto;
overflow-x: hidden;
}

.table thead {
  display: block;
  overflow-x: hidden;
}

.table tbody tr {
  display: flex;
}