
.exp-modal-outer-container {
    display: flex;
    flex-flow: column;
}

.exp-modal-form {
    display: flex;
    flex-flow: row;
}

.exp-modal-form-container-1 {
    display: flex;
    flex-flow: column;
    margin-right: 40px;
}

.exp-modal-form-container-2 {
    display: flex;
    flex-flow: column;
}


.exp-modal-item {
    margin-bottom: 20px;
}

/*  */

.creds-outer-container {
    display: flex;
    flex-flow: column;
}
