.searchIncome__selectButton {
  @media screen and (max-device-width: 767px) {
    width: 245px;
    height: 65px;
    border-radius: 6px;
    padding-top: 17px;
    margin-left: 0px !important;
    margin-bottom: 20px;
  }
}

.searchIncome__selectButtonTxt {
  @media screen and (max-device-width: 767px) {
    margin-left: 0px;
  }
}
