

.create-client-modal-form {
    display: flex;
    flex-flow: row wrap;
   justify-content: space-between;
   height: 300px;
   margin-top: 0px;
   padding-left: 70px;
   padding-right: 70px;
}

