#modal-overlay {
	z-index: 1000;
}

#modal-container {
	background-color: #fff;
	width: 560px;
	height: 550px;
	border-radius: 20px;
}


#modal-1-content {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}

/**/

.modal-title-container {
	display: flex;
	justify-content: flex-start;
	margin-left: 50px;
	margin-top: 30px;
}

.modal-title {
	/* font-family: "Roboto";
	font-weight: 700; */
}

/**/

#add-button {
	/* margin-top: 3em; */
	padding: 5px 15px 5px 15px;
	color: #C93636;
	cursor: pointer;
	border-radius: 10px;
	font-size: 20px;
	background-color: transparent;
	font-weight: 200;
	border: 1px solid #C93636;
	box-shadow: 0 2px 20px -10px rgba(115,115,115,0.50);
	outline: none;
}

#add-button:hover {
	transition: .3s;
	background-color: #FBF6F6;
}


#modal-title {
	font-family: 'Sarabun';
	font-weight: 900;
	font-size: 42px;
	color: #443C3C;

	margin-left: auto;
	margin-right: auto;
	
	/* margin-top: 22px; */
	/* margin-bottom: -.5em; */
}
/*  */


/* MODAL BUTTON */
.modal-button-container {
	margin-top: -25px;
	margin-right: 0px;
    display: flex;
    justify-content: flex-end;
}

.modal-button {
    margin-top: 20px;
    font-size: 16px;
    background: #a21022;
    border: 0px;
    width: 240px;
	height: 65px;
    border-radius: 8px;
    font-family: "Sarabun";
    font-weight: 700;
    color: #fff;
	background: #D0021B;
	box-shadow: 0 0 4px 0 rgba(211,185,185,0.99);
}

.modal-button:hover {
	background: #D6394A;
    box-shadow: 0 0 10px 0 rgba(211,185,185,0.99);
    transition: .3s;
    cursor: pointer;
  }

.modal-button:focus {
    outline: none;
  }






/*  */
#modal-form {
	display: flex;
	flex-flow: column;
}

#modal-form-container-1 {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}


#modal-form-item-1 {
	display: flex;
	flex-flow: column;
}

#modal-form-item-2 {
	display: flex;
	flex-flow: column;
	margin-left: 28px;
}

#modal-form-item-3 {
	display: flex;
	flex-flow: column;
}


#modal-input-title {
	color: #666666;
	font-weight: 200;
	text-align: left;
	margin-left: 10px;
	margin-top: 20px;
	margin-bottom: 0;
}


#modal-input-1 {
	padding-top: 0;
	height: 50px;
	width: 312px;
	padding-left: 20px;
	padding-right: 20px;
	border-radius: 30px;
	border: none;
	background: rgba(57,83,149,0.10);
	font-size: 18px;
	font-weight: 200;
	color: #666666;
}

#modal-input-description {
	resize: vertical;
	height: 135px;
	width: 312px;
	padding: 10px 20px 10px 20px;
	border-radius: 20px;
	border: none;
	background: rgba(57,83,149,0.10);
	font-size: 18px;
	font-weight: 200;
	color: #666666;
}

#modal-input-notes {
	resize: vertical;
	height: 65px;
	width: 692px;
	padding: 10px 20px 10px 20px;
	border-radius: 20px;
	border: none;
	background: rgba(57,83,149,0.10);
	font-size: 18px;
	font-weight: 200;
	color: #666666;
}
