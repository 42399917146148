.UpgradeClientVersion {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.upgrade-version-cc-logo {
    width: 60px;
}

.upgrade-version-wrapper {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    height: 300px;
  
}

.upgrade-version-content-header-wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-bottom: 20px;
}

.upgrade-version-content-body-wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
   
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;


   
}



.upgrade-version-content-body-list-wrapper {
    margin-bottom: 15px;
   text-align: left;
}



.upgrade-version-btn {
    margin-top: 20px;

    background-color: #D01E1B;
    color: #FFFFFF;
    font-family: 'DM Sans';
    font-weight: 700;
    font-size: 18px;
    padding: 10px 40px;
    outline: none;
    border: none;
    border-radius: 4px;

    &:hover {
        opacity: .7;
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }
}