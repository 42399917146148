.firmContainer {
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  padding-left: 50px;
  padding-right: 50px;

  @media screen and (max-width: 1200px) {
    flex-flow: column;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.firm-page-wrapper {
  display: flex;
  flex-flow: column;
  // width: 100%;
  // justify-content: space-between;
  padding-top: 10px;
  padding-right: 80px;

  @media screen and (max-width: 767px) {
    padding-right: 0px;
    justify-content: center;
    // padding-left: 20px;
    // padding-right: 20px;
  }
}

.lawFirmResult__logo {
  width: fit-content;
  height: 70px;
  margin-right: 30px;

  @media screen and (max-width: 1200px) {
    margin-right: 20px;
  }
}

.lawFirmResult__reviews__logoWrap {
  display: flex;
  flex-flow: row;
  margin-bottom: -40px;

  @media screen and (max-width: 1200px) {
    margin-bottom: 0px;
    justify-content: center;
  }
}

.lawFirmResult__reviewsWrap {
  margin-top: 50px;
  width: 100%;
}

.lawFirmResult__bestReview {
  margin-bottom: 50px;
}

.lawFirmResult__name {
  font-size: 28px;
  font-weight: 500;
  font-family: "DM Sans";
  color: #3f2223;
  margin-bottom: 60px;

  @media screen and (max-width: 1200px) {
    display: none;
  }
}
