.searchUrgency__selectButton {
  @media screen and (max-device-width: 767px) {
    // height: 182px;
    width: 285px;
    height: 66px;

    border-radius: 6px;

    border: 1px solid #d8d8d8;
    margin-bottom: 20px;
  }
}
