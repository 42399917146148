@import '../../../style/variables';

.SignUp {
  // @include flex-col;

  padding: 60px 20px;
  display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	width: 100%;
  height: 100vh;
}



.signup-header {
  text-align: center;
  // margin-bottom: 25px;
  img {
    width: 70px;
    margin-bottom: 30px;
  }
  p {
    font-weight: 200;
    margin-bottom: 0;
  }

}

.copyright-signup {
  text-align: center;
  font-size: .9em;
  color: #6c757d;
  margin-top: 10px;
  margin-bottom: 20px;
}

.signup-title {
  font-family: "Sarabun";
	font-weight: 700;
    font-size: 24px;
    color: #1F0F0F;
    text-align: center;
}



.signup-form-container {
  margin-top: 25px;
  // @include flex-col;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  
}

// .signup-input {
//   @include input;
// }

// .signup-button {
//   @include btn-submit;
//   margin-top: 50px;
// }


// 
.signup-input  {
  outline: none;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 300;
  background: #FFFFFF;
  color: #111111;
  border-radius: 6px;
  border: 1px solid #D3B9B9;
  padding: 10px 23px;
  width: 300px;
  /* margin-right: 20px; */

  &:focus {
      border: 1px solid #3689FF;
  }
}



/* SIGNUP BUTTON */
.signup-button {
  margin-top: 20px;
  font-size: 18px;
  background: #a21022;
  border: 0px;
  width: 300px;
height: 70px;
  border-radius: 8px;
  font-family: "Sarabun";
  font-weight: 700;
  color: #fff;
background: #D0021B;
  box-shadow: 0 0 4px 0 rgba(211,185,185,0.99);
  
  &:hover {
      background: #D6394A;
      box-shadow: 0 0 10px 0 rgba(211,185,185,0.99);
      transition: .3s;
      cursor: pointer;
  }

  &:focus {
      outline: none;
  }
}

.input-container {
  margin-top: .5em;
}

.input-container-fields {
  margin-top: -1em;
  margin-bottom: 1em;
}


/* ERRORS */
.is-invalid {
  border: 1px solid red;
}


// SIGNUP REQUEST INPUTS
.first-name-input, .last-name-input {
  width: 180px;
}
.email-input {
  width: 380px;
  margin-bottom: 12px;
}




.law-title-input, .law-field-input {
  outline: none;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 300;
  background: #FFFFFF;
  color: #111111;
  border-radius: 10px;
  border: 1px solid #D3B9B9;
  padding: 10px 23px;
  height: 45px;
  width: 180px;
  /* margin-right: 20px; */

  &:focus {
      border: 1px solid #3689FF;
  }
}


// Password reset reqeust
.PasswordResetRequest {
  padding: 60px 20px;
  display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	width: 100%;
  height: 90vh;
}

.password-reset-request-form {
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.password-reset-button { 
  height: 60px;
}

.back-to-login-link { 
  font-family: 'Roboto';
  color: rgb(212, 81, 96);

  &:hover {
    text-decoration: none;
    color: rgb(185, 34, 51);
    transition: .3s;
  }
}

// 
.check-password-reset-email-container {
  display: flex;
  flex-flow: column;
	justify-content: center;
	align-items: center;
}

.check-password-reset-email-container img {
  width: 250px;
  margin-top: 30px;
  margin-bottom: 0;
}

// 
.forgot-password-link {
  margin-top: 5px;
  display: flex;
	justify-content: flex-end;
	align-items: flex-end;
  font-size: 14px;
  font-family: 'Roboto';
  color: #D0021B;

  &:hover {
    text-decoration: none;
    color: rgb(185, 34, 51);
    transition: .3s;
  }
}

// 
.reset-password-container {
  margin-bottom: 1em;
}

.password-reset-reminder {
  width: 400px;
}

