
.side-navbar-container {
    height: 100%; /* Full-height: remove this if you want "auto" height */
    width: 90px; /* Set the width of the sidebar */
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    /* z-index: 1; */
    top: 0; /* Stay at the top */
    left: 0;
  
    overflow-x: hidden; /* Disable horizontal scroll */
    /* background: #A40D1D; */
    background: #FFFFFF;
    /* box-shadow: 0 0 20px -10px rgba(115,115,115,0.50); */
     border-right: 1.4px solid #E8E1E1;
  }
  
  /**/
  
  .side-navbar-items {
      display: flex;
      flex-flow: column;
      padding-bottom: 55px;
  }
  
  /*  */
  
  .side-navbar-logo-container {
      display: flex;
      justify-content: center;
      margin-top: 25px;
  }
  
  .side-navbar-logo {
      width: 50px;
  }
  /*  */
  .side-navbar-links-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      margin-top: 70px;
  }
  
  .side-navbar-link-button-1, .side-navbar-link-button-2, .side-navbar-link-button-3  {
      display: flex;
      justify-content: center;
      width: 48px;
      height: 50px;
      border-radius: 8px;
      border: none;
      background-color: #EDEDED;
      margin-bottom: 42px;
  }
  
  .side-navbar-link-button-2 {
      background-color: #D0021B;
  }
  
  .side-navbar-link-button-1:hover, .side-navbar-link-button-2:hover, .side-navbar-link-button-3:hover {
      background-color: #D0021B;
      transition: .15s
  }
  
  .side-navbar-profile-icon {
      width: 21px;
  }
  
  .side-navbar-home-icon  {
      width: 27px;
  }
  
  
  /*
  .selected {
      background-color: #D0021B;
  }
   */
  
  
  
  
  
  /*  */
  
  .navbar-item {
      display: flex;
      flex-flow: column;
      text-align: left;
  }
  
  .navbar-item h2 {
      font-family: "Sarabun";
      font-size: 20px;
      color: #2222;
      margin-bottom: 20px;
      margin-left: 30px;
      margin-top: 25px;
  }
  
  #navbar-link {
      font-family: "Sarabun";
      font-size: 18px;
      color: #111111;
      text-decoration-line: none;
      margin-bottom: 15px;
      margin-left: 50px;
  }
  
  #navbar-link:hover {
      color: #E1E1E1;
  }
  
  
  /**/
  
  .navbar-logo {
    margin-left: 32px;
      margin-bottom: 10px;
      padding-top: 55px;
  }
  
  .navbar-logo img {
      width: 180px;
  }
  
  /**/
  
  
  hr {
      color: #fff;
      width: 180px;
      margin-top: 25px;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*  */
  
  .navbar-customize {
      background: #fff;
    //   border: 1px solid #979797;
      /*height: 5em;*/
      /*margin-right: -4px;*/
  }
  
  .logo {
      width:8.5em;
  }
  
  
  
  .nav-item h3 {
      font-size: 1em;
      margin-top: .7em;
      margin-right: 2em;
  }
  
  .nav-item-shift {
      margin-right: 20em;
  }
  
  .navbar-toggler-icon {
  
      color: black;
  }
  
  .auth-button {
      background: #fff;
      border: 1px solid #D0021B;
      padding: .55em 1em .55em 1em;
      border-radius: 4px;
      font-family: 'Sarabun';
      font-weight: 600;
      font-size: .9em;
      color: #D0021B;
  }
  
  .auth-button:hover {
      border: 1px solid #BF0118;
      color: #BF0118;
      transition: .6s;
      cursor: pointer;
  }
  
  .auth-button:focus {
      outline: none;
  }
  