@font-face {
  font-family: "Circularstd bold";
  src: url("./fonts/CircularStd-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Circularstd";
  src: url("./fonts/CircularStd-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Circularstd medium";
  src: url("./fonts/CircularStd-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Circularstd book";
  src: url("./fonts/CircularStd-Book.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

.ccSearch__header {
  display: flex;
  justify-content: center;

  @media screen and (max-device-width: 767px) {
    border-bottom: 1px solid #b3b8bc;
  }
}

.lawyer-search-navbar-logo {
  height: 45.63px;
  width: 209.94px;
  margin-right: 120px;
  margin-top: 50px;

  @media screen and (max-device-width: 767px) {
    margin-right: 20px;
    margin-top: 20px;
    height: 35px;
  }
}

.explainCase__content {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  margin-top: 50px;
  margin-bottom: 38px;
  width: 100% !important;

  @media screen and (max-device-width: 767px) {
    margin-top: 20px;
    min-height: auto;
  }
}

.explainCase__content__title {
  font-family: "Circularstd bold";
  font-size: 52px;
  color: #000;
  letter-spacing: -1.3px;
  text-align: center;
  width: 763px;
  @media screen and (max-device-width: 767px) {
    width: 400px;
    font-size: 24px;
    padding-left: 42px;
    padding-right: 42px;
    font-weight: 700;
  }
}

.explainCase__content__subtitle {
  font-family: "Dm Sans";
  font-size: 20px;
  margin-top: 23px;
  color: #6a7a89;

  @media screen and (max-device-width: 767px) {
    margin-top: 0px;
    display: none;
  }
}

.explainCase__content__input {
  margin-top: 55px;
  border-radius: 19px;
  padding: 32px 30px;
  border: none;
  font-size: 24px;
  font-family: "Dm Sans";
  color: #4c2323;
  min-height: 20px;
  max-height: 100px;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 1px solid black;
  width: 763px;
  outline: none;
}

.demo {
  // width: 100px;
  padding-left: 28px;
  max-height: 300px;

  overflow-x: hidden;
  overflow-y: scroll;
  // background-color: red;
  width: 663px;

  border-radius: 8px;
  // padding: 22px 30px;
  background-color: #fff;
  font-size: 21px;
  font-family: "Dm Sans";
  color: #000000;
  outline: none;

  @media screen and (max-device-width: 767px) {
    font-size: 18px;
  }
}

.demo:empty:before {
  content: attr(data-placeholder) !important;
  color: #000 !important;
}
.featherIcon__sendContainer {
  width: 25px;
  height: 25px;
}
.featherIcon__send {
  padding-left: 0px;
  color: #000;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.explainCase__inputContainer {
  // margin-top: 55px;
  padding-right: 20px;
  max-height: 300px;
  min-height: 65px !important;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  // width: 100%;
  border-radius: 8px;
  align-items: center;
  background-color: #fff;

  @media screen and (max-device-width: 767px) {
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  font-family: "DM Sans";
  color: #4c2323;

  pointer-events: none;
  display: block;
}

.explainCase__content__btn,
.searchCriteria__content__btn {
  background: #157ffb;
  border-radius: 8px;
  width: fit-content;
  padding: 14px 22px;
  border-radius: 8px;
  font-size: 21px;
  color: #fff;
  font-family: "DM Sans";
  font-weight: 700 !important;

  margin-top: 76px;
  outline: none;
  min-width: 201px;
  border: none;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-device-width: 767px) {
    margin-top: 50px;
  }
}

.input-like-div {
  width: 100px;
  min-height: 20px;
  max-height: 300px;

  overflow-y: scroll;
  //   border: 1px solid black;
  background-color: #fff;
  width: 763px;
  margin-top: 55px;
  border-radius: 19px;
  padding: 32px 30px;
  border: none;
  font-size: 24px;
  font-family: "Dm Sans";
  color: #4c2323;
  outline: none;
}

.explainCase__content__form {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.explainCase__footer {
  display: flex;
  flex-flow: column;

  margin-top: 66px;

  @media screen and (max-device-width: 767px) {
    display: none;
  }
}

.explainCase__footer__poweredbyIcon {
  height: 30px;
  margin-bottom: 19px;
}

.explainCase__footer__disclaimer {
  font-family: "Dm Sans";
  color: #6a7a89;
  font-size: 20px;

  @media screen and (max-device-width: 767px) {
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.explainCase__content__chat {
  padding: 29px 37px 36px 37px;
  background-color: #f2f9ff;
  border: 1px solid #eeeeee;
  border-radius: 25px;
  margin-top: 41px;
  width: 800px;

  height: 100%;
  // max-height: 709px;
  // overflow-y: scroll;

  @media screen and (max-device-width: 767px) {
    width: 400px;
    margin-top: 21px;
    padding: 26px 17px 26px 17px;
    background-color: #f2f9ff;
    border: none;
  }
}

.explainCase__content__chat__msg,
.explainCase__content__chat__msg--loading {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  margin-top: 36px;

  padding: 22px 29px 22px 29px;
  background-color: #fff;

  width: 100%;
  border-radius: 8px;

  min-height: 66px;
}

.explainCase__content__chat__msg {
  @media screen and (max-device-width: 767px) {
    margin-top: 0px;
    margin-bottom: 36px;
  }
}

.explainCase__content__chat__msg--loading {
  background-color: #f2f9ff;
}

.explainCase__content__chat__msg__txt {
  font-family: "Dm Sans";
  color: #0b1151;
  font-size: 18px;
  margin-left: 15px;
  margin-top: 5px;

  @media screen and (max-device-width: 767px) {
    font-size: 16px;
  }
}

.explainCase__content__chat__result {
  margin-top: 34px;
  margin-bottom: 15px;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.explainCase__content__chat__result__txt {
  font-family: "Dm Sans";
  color: #157ffb;
  font-weight: 700;
  font-size: 18px;
  max-width: 400px;
  text-align: center;
}

.explainCase__content__chat__result__continueBtn {
  height: 56px;
  width: 201px;
  background-color: #157ffb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 21.5px;
  padding-right: 24px;
  border-radius: 8px;
  margin-top: 28px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.explainCase__content__chat__result__continueBtn__txt {
  color: #fff;
  font-size: 21px;
  font-family: "Dm Sans";
  font-weight: 700;
}

.explainCase__content__chat__result__orTxt {
  color: #6a7a89;
  font-size: 18px;
  font-family: "Dm Sans";
  margin-top: 19px;
}

.nomsgSpace {
  height: 40px;
}

.explainCase__content__chatExamples {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 20px;

  @media screen and (max-device-width: 767px) {
    flex-flow: row;
    margin-top: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 10px;
    // margin-left: 50px;
  }
}

.explainCase__content__chatExamples__txt {
  font-family: "Dm Sans";
  color: #6a7a89;
  font-size: 20px;
  margin-top: 5px;
  @media screen and (max-device-width: 767px) {
    margin-left: 7px;
    margin-top: -1px;
    font-weight: 400;
    font-size: 18px;
  }
}

.explainCase__content__chatExamplesContainer {
  display: flex;
  flex-flow: column;
  align-items: center;
  @media screen and (max-device-width: 767px) {
    justify-content: flex-start;
  }
}
.explainCase__content__chatExamples__card {
  padding: 11px 20px;
  width: 100%;
  max-width: 500px;
  background-color: #4497f7;
  border-radius: 8px;
  margin-bottom: 25px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  display: flex;
  align-items: center;
  @media screen and (max-device-width: 767px) {
    // width: 100%;
    margin-bottom: 27px;
    background-color: #4497f7;
    padding: 11px 23px;
  }
}

.explainCase__content__chatExamples__card__txt {
  font-family: "Circularstd medium";
  color: #fff;
  font-size: 21px;
  margin-right: 5px;
  width: 100%;
  font-weight: 500;

  @media screen and (max-device-width: 767px) {
    font-weight: 700;
    font-size: 18px;
  }
}

.explainCase__content__chatExamples__card__arrowRightIcon {
  margin-left: 10px;
  width: 10%;
}

.explainCase__content__chatExamplesCards {
  margin-top: 36px;

  @media screen and (max-device-width: 767px) {
    margin-top: 19px;
  }
}

.featherIcon__send__iconContainer {
  height: 50px;
  width: 50px;
  background-color: #f5f5f5;

  // padding: 10px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  & > svg {
    transition: 0.3s;
    color: #000;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  @media screen and (max-device-width: 767px) {
    width: 100px;
  }
}

.featherIcon__send__iconContainer--active {
  background-color: #157ffb;

  & > svg {
    color: #fff;
  }
}
