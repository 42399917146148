.social-profile-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  background-color: white;
}

.social-profile-best-rating-number-wrapper {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media screen and (max-device-width: 767px) {
    flex-flow: column;
  }
}

.social-profile-best-rating-star-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-device-width: 767px) {
    margin-bottom: 20px;
  }
}

.social-profile-most-helpful-review-text-container {
  display: flex;
  justify-content: center;

  @media screen and (max-device-width: 767px) {
    width: 100%;
    text-align: center;
  }
}

.social-profile-return-to-matches-link {
  display: flex;
  align-items: center;
  font-family: "DM Sans";
  color: #806b6c;

  &:hover {
    text-decoration: none;
    color: #d0021b;

    .list-icon {
      stroke: #d0021b;
    }
  }
}

.social-profile-return-to-matches-link > span {
  margin-left: 10px;
}

.social-profile-cc-guarantee-container {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #e9eef4;
  // padding: 0px 80px 0px 50px;
  min-width: 700px;
  max-width: 60%;
  border-radius: 12px;
  min-height: 160px;
  max-height: 160px;
  padding-left: 30px;

  @media screen and (max-device-width: 1024px) {
    max-width: 100%;
  }

  @media screen and (max-device-width: 992px) {
    width: 100%;
    max-width: 100%;
    min-width: auto;
  }

  @media screen and (max-device-width: 767px) {
    justify-content: center;
    width: 100%;
    min-width: auto;
    flex-flow: row wrap;
    padding: 30px;
    min-height: 320px;
    max-height: 320px;
  }
}

.cc-guarantee-svg {
  width: 100px;
  margin-right: 30px;
  @media screen and (max-device-width: 992px) {
    width: 145px;
  }
  @media screen and (max-device-width: 767px) {
    margin-right: 0px;
    margin-bottom: 20px;
    width: 145px;
  }
}

.social-profile-txt-bold {
  font-weight: 700;
}

.social-profile-cc-guarantee-txt-wrapper {
  @media screen and (max-device-width: 767px) {
    text-align: center;
  }
}

.social-profile-cc-guarantee-txt-wrapper > h4 {
  margin-bottom: 15px;
}

.social-profile-cc-guarantee-txt-wrapper > p {
  width: 80%;
  @media screen and (max-device-width: 767px) {
    width: 100%;
  }
}

// map

.mobile-show {
  display: none;

  @media screen and (max-device-width: 767px) {
    display: block;
  }
}

.lawyer-search-cc-guarantee-container {
  margin-top: 15px;
  // min-width: 800px;
  max-height: 360px;
  max-width: 65%;
  height: auto;

  //   min-width: 800px;

  @media screen and (max-width: 1102px) {
    width: 90%;
  }

  @media screen and (max-width: 992px) {
    min-width: 600px;
    // height: 100%;
  }

  @media screen and (max-width: 767px) {
    min-width: 100%;
    height: 100%;
    width: 100%;
  }
}
