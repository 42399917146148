.NoPremiumLogin {
  display: flex;
  flex-flow: row;
}

.noPremiumLogin__content {
  display: flex;
  flex-flow: column;
  padding-left: 88px;
  padding-right: 88px;
  padding-top: 73px;
  background-color: #fff;
}

.noPremiumLogin__content__title {
  font-family: "Dm Sans";
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 10px;
}

.noPremiumLogin__content__subtitle {
  font-family: "Dm Sans";
  font-size: 18px;
  font-weight: 400;
}

.noPremiumLogin__content__pricing {
  background: #d0021b;
  border-radius: 8px;

  padding: 24px;
  margin-top: 26px;
  padding-top: 32px;
  // padding-bottom: 32px;
}

.noPremiumLogin__content__pricing__header {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.noPremiumLogin__content__pricing__header__txt {
  font-family: "DM Sans";
  font-size: 21px;
  color: #ffffff;
  font-weight: 700;
}

.noPremiumLogin__content__pricing__header__price {
  font-family: "DM Sans";
  font-size: 16px;
  color: #ffffff;
}

.flex-column {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.noPremiumLogin__content__pricing__header__subtxt {
  color: #ffffff;
}

.noPremiumLogin__content__pricing__content__txt,
.noPremiumLogin__content__pricing__content__headerTxt {
  font-family: "DM Sans";
  font-size: 16px;
  color: #ffffff;
}

.noPremiumLogin__content__pricing__content__txt {
  margin-left: 10px;
  margin-bottom: 10px;
}

.noPremiumLogin__content__pricing__content {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 30px;
}

.noPremiumLogin__content__pricing__content__item {
  margin-bottom: 10px;
  margin-left: 10px;
}
