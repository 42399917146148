.AccountInfo {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    height: 100%;
    min-height: 80vh;
    width: 100%;
}
.acc-info-content-container {
    margin: 25px;
    margin-top: 85px;
    margin-left: 620px;
    height: 100%;
    
}

.acc-info-email {
    display: flex;
    flex-flow: row;
    justify-content: baseline;

    .acc-info-email-txt-view {
        display: flex;
        flex-flow: column;

        strong {
            font-family: 'Roboto';
            font-size: 18px;
            color: #8B7474;
        }

        span {
            font-family: 'Roboto';
            font-weight: 300;
            font-size: 16px;
            color: #222222;
        }

    }

    .acc-info-email-txt-edit {
        border: none;
        outline: none;
        margin-top: 1.4em;
        margin-left: 2em;
        font-family: 'Roboto';
        font-size: 18px;
        color: #D0021B;

        &:hover {
            opacity: .8;
            cursor: pointer;
        }
    }
    
}

.acc-info-edit-email-txt-field {
    margin-top: 1em;
}

.acc-info-password {
    margin-top: 1.5em;
    display: flex;
    flex-flow: column;

    strong {
        font-family: 'Roboto';
        font-size: 18px;
        color: #8B7474;
    }

    button {
        margin-top: .5em;
        font-size: 16px;
        border: 0px;
        width: 100px;
        height: 50px;
        border-radius: 8px;
        font-family: "Sarabun";
        font-weight: 700;
        color: #fff;
        background: #D0021B;
        box-shadow: 0 0 4px 0 rgba(211, 185, 185, 0.99);

        &:hover {
            background: #D6394A;
            box-shadow: 0 0 10px 0 rgba(211, 185, 185, 0.99);
            transition: .3s;
            cursor: pointer;
          }
      
          &:focus {
            outline: none;
          }
    }
          
}



.acc-info-delete-acc {
    margin-top: 6em;
    display: flex;
    flex-flow: column;

    strong {
        font-family: 'Roboto';
        font-size: 16px;
        color: #FF4444;
    }

    button {
        opacity: .5;
        margin-top: .5em;
        font-size: 16px;
        border: 0px;
        width: 100px;
        height: 50px;
        border-radius: 8px;
        font-family: "Sarabun";
        font-weight: 700;
        color: #FF4444;
        background: #ececec;
        border: 1px solid #FF4444;
        box-shadow: 0 0 4px 0 rgba(211, 185, 185, 0.99);

        &:hover {
            opacity: 1;
            color: #FF4444;
            background: #ececec;
            border: 1px solid #FF4444;
            box-shadow: 0 0 4px 0 rgba(211, 185, 185, 0.99);
    
            transition: .3s;
            cursor: pointer;
          }
      
          &:focus {
            outline: none;
          }
    }
}