
@import '../../../style/_variables.scss';

.CreateProfile {
    @include flex-center;
    justify-content: center;
    width: 100%;
    margin-left: 200px;
    background: #F6F0F0;
  }

.create-profile-profile-pic-w {
    // @include flex-center;
     border: solid 1px #D4B9BA;
     border-radius: 8px;
     height: 140px;
     width: 160px;
     margin-bottom: 40px;
     background-color: #FFFFFF;
  
     &:hover {
         border: solid 1px #3689FF;
         opacity: .5;
         cursor: pointer;
     }
  }

  
.new-lawfirm-uploaded-pic {
    padding: 5px;
    height: 140px;
    max-width: 160px;
    border-radius: 8px;
}


.create-profile-form-container {
    margin: 25px;

   
    // box-shadow: 0 0 20px -10px rgba(115,115,115,0.50);
    border-radius: 15px;
    height: auto;
    padding: 30px 50px;
    max-width: 1000px;
}

/*  */
.law-field-input-small {
    width: 300px;
}


// 
.edit-profile-container-4 {
    margin-top: 40px;
}

.edit-avail-form-container-create-profile { 
    display: flex;
    flex-flow: row wrap;
    background: #FFFFFF;
    border-radius: 15px;
    height: auto;
    padding: 30px 30px;
}

.edit-avail-select-container-create-profile {
    display: flex;
    flex-flow: row;
    margin-left: 20px;
}

.edit-avail-select-input-2 {
    margin-right: 50px;
}

.law-portal-select-list-input-create-profile {
    outline: none;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 300;
    background: #FFFFFF;
    color: #111111;
    border-radius: 8px;
    border: 1px solid #D3B9B9;
    padding: 5px 10px;
    width: 150px;
    height: 40px;
}


// 
.create-profile-spinner-container {
    @include flex-center;
    justify-content: center;
    width: 100%;
}

.create-profile-loading-spinner {
    width: 100px;
}



// 

.create-profile-c2-input-field-container {
    @include flex-row-wrap;
    align-items: flex-start;
   
    
    // padding: 30px 50px 30px 70px;
  }


 
  .create-profile-c2-input-group-2 {
    margin-left: 40px;
    margin-right: 40px;
    // width: auto;
   
    // justify-content: space-between;
  }


  .create-profile-c3-r2-input-group {
    @include flex-row-wrap;
 
  }



//   


.create-profile-container-3 {
    margin-top: 40px;
}

.create-profile-input-container-3 {
    display: flex;
    flex-flow: column;
}


.create-profile-icon-label {
    font-family: 'Roboto';
    font-size: 18px;
    margin-left: 10px;
    color: #007AFF;
}

.create-profile-icon-wrap {
    width: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    background-color: #CBE5FF;
   
    border-radius: 10px;
    padding: 5px;


	&:hover {
		opacity: .8;
		cursor: pointer;
		text-decoration: none;
	  }
  
    &:focus {
    outline: none;
    }

    & {
        margin-top: 20px;
    }
}

// 

.create-profile-social-input-wrap-1,
.create-profile-social-input-wrap-2,
.create-profile-social-input-wrap-3 
{
    @include flex-row;
    align-items: center;
}

.create-profile-social-x-icon {
    margin-left: 20px;

    &:hover {
		opacity: .8;
		cursor: pointer;
	  }
  
}


  