.searchCriteria__body {
  margin-top: 50px;
  margin-bottom: 100px;

  @media screen and (max-device-width: 767px) {
    margin-top: 20px;
    margin-bottom: 0px;
  }
}

.common__selectButton {
  width: 209.91px;
  height: 182px;
  background: #ffffff;
  border-radius: 19px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 50px;
  border: 1.5px solid #bac3cb;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  @media screen and (max-device-width: 767px) {
    // height: 182px;
    width: 100%;
    height: 66px;

    padding: 10px 20px;
    border-radius: 6px;

    border: 1px solid #d8d8d8;
  }
}

.common__selectButton--searchUrgency {
  flex-flow: column;
}

.common__singleSelectBtn {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 25px;
  height: auto;
}

.common__selectButton--active {
  background-color: #eef6ff;
  border: 1.5px solid #157ffb;
}

.common__selectButton__txt {
  font-family: "Dm Sans";
  font-weight: 700;
  font-size: 18px;
  color: #363636;
  margin-top: 19px;
  text-align: center;

  @media screen and (max-device-width: 767px) {
    margin-top: 0px;
    font-size: 17px;
    margin-left: 17px;
  }
}

.common__selectButton__subtxt {
  font-family: "Dm Sans";
  font-size: 18px;
  color: #363636;
  font-weight: 400;
  text-align: center;

  @media screen and (max-device-width: 767px) {
    margin-top: 0px;
    font-size: 17px;
    margin-left: 0px;
  }
}

.common__selectButton--consecutive,
.common__selectButton--consecutive--row2 {
  margin-left: 31.09px;

  @media screen and (max-device-width: 767px) {
    margin-left: 0px;
  }

  @media screen and (max-device-width: 767px) {
    margin-top: 23px;
  }
}

.common__buttonContainer {
  display: flex;
  flex-flow: row wrap;
  margin-top: 50px;
  max-width: 710px;
  @media screen and (max-device-width: 767px) {
    flex-flow: column;
    margin-top: 30px;
  }
}

.searchCriteria__content__btn {
  width: 239px;
  height: 66px;
  flex-flow: row;

  padding: 10px 20px;
  border-radius: 6px;
  // margin-right: 50px;
  // margin-left: 50px;
}

.searchCriteria__content__btnTxt {
  font-size: 18px;
}

.common__selectButton--row2,
.common__selectButton--consecutive--row2 {
  margin-top: 23px;
}

.disabled {
  opacity: 0.3;
  &:hover {
    opacity: 0.3;
  }
  &:focus {
    opacity: 0.3;
  }
}

.searchCritera__selectButton {
  @media screen and (max-device-width: 767px) {
    flex-flow: row;
  }
}
