

.fallback-firm-card-container { 
    margin-top: 2em;
    // &:nth-child(1) {
    //     margin-top: 2em;
    // }

    height: auto;
    max-width: 65%;
    // margin-top: 2em;
    max-height: 327px;
    min-width: 800px;
    background: #ffffff;
    border: 2px solid #cec4c4;
    border-radius: 8px;

    height: 193px;
    min-height: 193px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1102px) {
        width: 90%;
      }
    
      @media screen and (max-width: 992px) {
        min-width: 600px;
        // height: 100%;
        min-height: 130px;
       
      }
    
      @media screen and (max-width: 767px) {
        min-width: 100%;
        height: 100%;
        width: 100%;
        border: 1px solid #cec4c4;
        border-radius: 6px;
        min-height: 92px;
      }

   
}