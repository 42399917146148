.SocialProfile {
  display: flex;
  flex-flow: row wrap;
  // height: 100vh;
  padding-left: 80px;
  padding-right: 100px;
  background-color: white;
  height: 100%;
  width: 100%;

  @media screen and (max-width: 1024px) {
    padding-bottom: 130px;
  }

  @media screen and (max-width: 992px) {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 130px;
  }
  @media screen and (max-device-width: 767px) {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 130px;
  }
}

.social-profile-section-title {
  font-family: "Sarabun";
  font-weight: 700;
  font-size: 1.2em;
}

.social-profile-profile-card-n-review-wrapper {
  display: flex;
  flex-flow: column;
  width: 70%;
  margin-bottom: 50px;

  @media screen and (max-width: 992px) {
    width: 100%;
  }
  @media screen and (max-device-width: 767px) {
    margin-bottom: 20px;
  }
}

.social-profile-header-container {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  // padding-left: 30px;
  padding-top: 150px;

  @media screen and (max-width: 992px) {
    display: block;
    flex-flow: row wrap;
  }
  @media screen and (max-device-width: 767px) {
  }
}

.social-profile-card-wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-top: -150px;
  margin-bottom: 0px;
  z-index: 1;
  padding: 20px;
  border-radius: 8px;

  @media screen and (max-width: 992px) {
    margin-top: -80px;
    justify-content: space-between;
  }
  @media screen and (max-device-width: 767px) {
    display: flex;
    flex-flow: column;

    margin-bottom: 10px;
    margin-top: -100px;
    margin-left: 0px;
  }
}

.social-profile-view-image {
  // margin-top: 4em;
  border: 3px solid white;
  min-width: 170px;
  max-width: 170px;
  min-height: 170px;
  max-height: 170px;
  border-radius: 50%;

  @media screen and (max-width: 992px) {
    min-width: 160px;
    max-width: 160px;
    min-height: 150px;
    max-height: 150px;
    margin-top: 20px;
    margin-right: 2em;
  }

  @media screen and (max-device-width: 767px) {
    margin-top: 0px;
    min-width: 130px;
    max-width: 130px;
    min-height: 130px;
    max-height: 130px;
    margin-right: 10px;
  }
}

//
.social-profile-header-text-container {
  display: flex;
  flex-flow: column;
  // justify-content: center;
  margin-left: 3em;
  margin-top: 8em;
  min-width: 100%;
  @media screen and (max-width: 992px) {
    display: block;
    flex-flow: row wrap;
    margin-left: 0em;
    margin-top: 4em;
  }
  @media screen and (max-device-width: 767px) {
    margin-top: 1em;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}

.social-employee-profile-header-text-container {
  display: flex;
  flex-flow: column;
  // justify-content: center;
  margin-left: 3em;
  margin-top: 8em;
  min-width: 100%;
  @media screen and (max-width: 992px) {
    display: block;
    flex-flow: column;
    margin-left: 0em;
    margin-top: 4em;
    min-width: auto;
  }
  @media screen and (max-device-width: 767px) {
    margin-top: 3em;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}

.social-profile-name {
  font-family: "DM Sans";
  color: #3e2222;
  font-size: 2em;
  font-weight: 700;
  @media screen and (max-device-width: 767px) {
    font-size: 24px;
  }
}

.social-profile-law-status {
  font-family: "DM Sans";
  font-size: 1.5em;
  color: #a38c8c;
  font-weight: 300;
}

//
.social-profile-website-links-container {
  display: flex;
  flex-flow: row;

  margin-top: 1em;
  height: 46px;

  a:nth-child(2) {
    margin-left: 1em;
    margin-right: 1em;
  }

  // a:last-child {
  //     margin-left: 1em;
  // }

  a:hover {
    text-decoration: none;
    // background-color: rgb(235, 245, 255);
    color: #443c3c;
    border-color: #bfbcbf;
  }

  @media screen and (max-device-width: 767px) {
    height: auto;
    a:first-child {
      margin-right: 0em;
    }
  }
}

.social-profile-website-link {
  font-family: "DM Sans";
  display: flex;
  color: #443c3c;
  padding: 10px;
  border: solid 0.5px #d0cdd0;
  background-color: #ffffff;
  border-radius: 4px;
  width: 120px;
  height: 46px;

  img {
    margin-right: 0.5em;
  }

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-device-width: 767px) {
    width: 110px;
    height: 46px;
  }
}

//

.social-profile-contact-container {
  position: fixed;
  right: 50px;
  width: 360px;
  // box-shadow: 0 0 4px 0 rgba(211,185,185,0.99);

  background-color: white;
  border: solid 1px #157ffb;
  border-radius: 4px;
  padding: 20px;
  // margin-left: 7em;

  height: auto;

  @media screen and (max-width: 1024px) {
    display: none;
  }

  @media screen and (max-width: 992px) {
    display: none;
    margin-left: 0em;
  }
  @media screen and (max-device-width: 767px) {
    width: 300px;
    height: 100%;
  }
}

.sp-contact-msg-container {
  display: flex;
  justify-content: space-evenly;
  flex-flow: column;
  text-align: center;
  margin-top: 20px;
  @media screen and (max-width: 992px) {
    margin-top: 50px;
    margin-left: 0em;
  }
  @media screen and (max-device-width: 767px) {
    width: 300px;
    height: 100%;
  }
}

.sp-contact-success-msg-txt {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 22px;
  color: #4cd964;
  margin-top: 26px;
  margin-bottom: 47px;
}

.sp-contact-info-msg-txt {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  color: #222222;
  margin-left: 50px;
  margin-right: 50px;
}

.social-profile-contact-title-container {
  display: flex;
  flex-flow: row;
  margin-bottom: 0.8em;

  strong {
    color: #157ffb;
    margin-left: 1em;
  }
}

.social-profile-contact-hours-container {
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  span {
    font-family: "Roboto";
    font-weight: 300;
    color: #9c9c9c;
  }
  hr {
    width: 50px;
  }
}

.social-profile-contact-hours-text-container {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  margin-right: 0.6em;

  hr {
    width: 100px;
  }

  span {
    font-size: 0.9em;
  }
}

/* CONTACT BUTTON */
.social-profile-contact-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-top: 20px;
  font-size: 18px;
  background: #0b1151;
  border: 0px;
  width: 100%;
  height: 60px;
  border-radius: 4px;
  font-family: "DM Sans";
  font-weight: 500;
  color: #fff;
  background: #157ffb;
  box-shadow: 0 0 4px 0 rgba(211, 185, 185, 0.99);

  &:hover {
    background: #157ffb;
    box-shadow: 0 0 10px 0 rgba(211, 185, 185, 0.99);
    transition: 0.3s;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  @media screen and (max-device-width: 992px) {
    // margin-top: 0px;
  }
}

//
.social-profile-content-container {
  margin-top: 3em;
  width: 70%;

  span {
    font-family: "Sarabun";
    font-weight: 700;
    font-size: 1.2em;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
  }
  @media screen and (max-device-width: 767px) {
  }
}

.social-profile-content-1 {
  margin-bottom: 50px;
}

.social-profile-content-1-title-container {
  display: flex;
  flex-flow: column;
  margin-bottom: 1em;

  div {
    display: flex;
    flex-flow: row;
    align-content: baseline;
  }

  img {
    margin-top: 8px;
  }

  hr {
    min-width: 500px;
    width: 100%;

    border: 1px solid rgb(236, 235, 235);

    @media screen and (max-device-width: 767px) {
      min-width: 200px;
    }
  }
}

.social-profile-reviews-header-wrap {
  display: flex;
  flex-flow: column;
  margin-bottom: 1em;

  .social-profile-line-wrap {
    display: flex;
    flex-flow: row;
    align-content: baseline;
  }

  img {
    margin-top: 8px;
  }

  hr {
    min-width: 500px;
    width: 100%;

    border: 1px solid rgb(236, 235, 235);

    @media screen and (max-device-width: 767px) {
      min-width: 200px;
    }
  }
}

.social-profile-content-1-body {
  display: flex;
  flex-flow: column;
  // justify-content: flex-start;
  margin-bottom: 1em;

  div {
    margin-left: 2em;
    @media screen and (max-device-width: 767px) {
      margin-left: 0;
    }
  }

  span {
    color: rgb(83, 73, 73);
    font-size: 1.1em;
  }

  p {
    margin-left: 0em;
  }
}
//

//
.social-profile-content-1-body-subtitle {
  display: flex;
  flex-flow: row;
  // margin-bottom: 12em;

  img {
    margin-left: 0.8em;
  }
}

//EDUCATION
.social-profile-content-education {
  margin-bottom: 50px;
}

.social-profile-content-edu-wrapper,
.social-profile-award-body-wrap {
  display: flex;
  flex-flow: column;
  margin-left: 2em;
  @media screen and (max-device-width: 767px) {
    margin-left: 0em;
  }
}

.social-profile-content-education-title {
  display: flex;
  flex-flow: column;
  margin-bottom: 1em;

  div {
    display: flex;
    flex-flow: row;
    align-content: baseline;
  }

  img {
    margin-top: 8px;
  }

  hr {
    min-width: 500px;
    width: 100%;
    border: 1px solid #dfd1d1;
    @media screen and (max-device-width: 767px) {
      min-width: 200px;
    }
  }
}

.social-profile-content-education-subtitle {
  display: flex;
  flex-flow: row;

  strong {
    color: #157ffb;
    font-size: 1.1em;
  }

  img {
    margin-right: 1.5em;
  }
}

.social-profile-award-wrap {
  display: flex;
  flex-flow: row wrap;

  strong {
    color: #157ffb;
    font-size: 1.1em;
  }

  img {
    margin-right: 1.5em;
  }
}

.cc-cert-wrap {
  margin-left: 40px;
  @media screen and (max-device-width: 767px) {
    margin-left: 0px;
    margin-top: 30px;
  }
}

.social-profile-content-education-body-subtitle {
  display: flex;
  flex-flow: column;
  margin-bottom: 2em;
}

.social-profile-content-education-body {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-left: 4.5em;
  margin-top: -1em;

  @media screen and (max-width: 992px) {
    flex-flow: column;
  }

  @media screen and (max-device-width: 767px) {
    flex-flow: column;
  }

  .social-profile-content-edu-deg-type {
    color: #443c3c;
  }

  .social-profile-content-edu-deg-major {
    color: #897f7f;
  }

  .social-profile-content-edu-uni-name {
    color: #806b6b;
    font-size: 16px;
  }

  .social-profile-content-edu-deg-date {
    font-family: "Sarabun";
    font-weight: 300;
    color: #897f7f;
  }
}
.social-profile-content-experience-body-2 {
  margin-left: 4em;
}

.social-profile-content-education-body-item-2 {
  margin-left: 5em;
  @media screen and (max-device-width: 992px) {
    margin-left: 0;
  }
  @media screen and (max-device-width: 767px) {
    margin-left: 0;
  }
}

//

.social-profile-content-bio-container {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;

  img {
    min-width: 130px;
    height: 130px;
    @media screen and (max-device-width: 767px) {
      margin-bottom: 2em;
    }
  }

  p {
    margin-left: 4em;
    max-width: 600px;
    @media screen and (max-device-width: 767px) {
      margin-left: 0em;
    }
  }
  @media screen and (max-device-width: 767px) {
    flex-flow: column;
    margin-left: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
}

//  INPUT FIELD - CONTACT FORM

.text-input-width {
  width: 140px;
  height: 40px;
}

.social-profile-booking-textarea {
  margin-top: 10px;
}

.sp-booking-date-container {
  display: flex;
  flex-flow: row;
  margin-top: 10px;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-device-width: 767px) {
    flex-flow: column;
  }
}

.text-field-name {
  width: 155px;
  height: 40px;
}

.text-field-email {
  width: 318px;
  height: 40px;
  @media screen and (max-device-width: 767px) {
    width: 100%;
    margin-bottom: 15px;
  }
}

// .map-container {
//     // background-color:purple;
//     // position: fixed;
//     // position: inherit;
//     // position: flex;
//     // position: block;
//     // height:10px;
//     // top:100;
// }

.text-field-date {
  width: 170px;
  height: 40px;
}

.text-field-time {
  width: 146px;
  height: 40px;
}

//
.social-profile-content-1-office {
  display: flex;
  flex-flow: column;
  margin-bottom: 50px;
  // height: 500px;
}

.address-sub-details {
  span {
    font-family: "Roboto";
    font-weight: 100;
    font-size: 1.2em;
    color: #716a6a;
  }
}

//

.social-profile-contact-msg-container {
  background-color: white;
  border: solid 1px #157ffb;
  border-radius: 4px;
  padding: 20px;
  margin-left: 7em;

  position: fixed;
  right: 50px;
  width: 360px;
  height: 480px;
  @media screen and (max-width: 1024px) {
    border: none;
    border-radius: 0px;
    border-top: solid 4px #157ffb;
    z-index: 2;
    position: fixed;
    right: 0px;
    bottom: 1px;
    width: 100%;
    background-color: white;
    height: auto;
  }
  @media screen and (max-device-width: 767px) {
    border: none;
    border-radius: 0px;
    border-top: solid 4px #157ffb;
    z-index: 2;
    position: fixed;
    right: 0px;
    bottom: 1px;
    width: 100%;
    background-color: white;
    height: auto;
  }
}

.social-profile-contact-book-button {
  margin-top: -30px;
}

.text-field-msg {
  width: 318px;
  @media screen and (max-device-width: 767px) {
    width: 100%;
  }
}

.consent-txt {
  margin-top: 1em;
  text-align: center;
  font-family: "Roboto";
  font-size: 0.7em;
}

// input stuff
.input-container {
  display: flex;
  flex-flow: column;
  margin-top: 0;
}

// Service input

.service-input {
  outline: none;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 300;
  background: #ffffff;
  color: #111111;
  border-radius: 8px;
  border: 1px solid #d3b9b9;
  padding: 5px 20px;
  width: 130px;
  height: 40px;
}

//   .select-list-input:focus {
//     border: 1px solid #3689FF;
//   }

//   .select-list-input-small {
//     font-size: 13px;
//     font-family: "Roboto";
//     font-weight: 400;
//     color: #9b9b9b;
//     margin-top: 10px;
//     margin-left: 1em;
//   }

.rating {
  // padding-bottom: 200px;
}

.rating-container {
  display: flex;
  flex-flow: row;
  min-width: 150px;

  @media screen and (max-device-width: 767px) {
    flex-flow: column;
  }
}

// .rating-txt {
//     width: 650px;
//     margin-left:40px;
//     @media screen and (max-device-width:767px){
//         width: 100%;
//     }
// }

.exp-img {
  width: 50px;
  height: 50px;
}

// sub items
.social-profile-sub-labels {
  color: #8a8585;
}

// SELECT LIST

.law-search-select-input {
  height: 40px;
  width: 155px;
}

//

.social-profile-txt-red {
  font-family: "DM Sans";
  color: #157ffb;
  margin-bottom: 0;
}

.social-profile-txt-yellow {
  font-family: "DM Sans";
  color: #e8c30c;
  margin-bottom: 0;
}

.social-profile-txt-dark {
  font-family: "DM Sans";
  color: #0b1151;
  margin-bottom: 0;
}

.social-profile-txt-light {
  font-family: "DM Sans";
  color: #6a7a89;
  margin-bottom: 0;
}

.social-profile-txt-bold {
  font-weight: 700;
}

.social-profile-txt-salmon {
  font-family: "DM Sans";
  color: #fc6970;
  margin-bottom: 0;
}

.social-profile-flex-row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  @media screen and (max-device-width: 1024px) {
    // flex-flow: column;
    justify-content: flex-start;
  }

  @media screen and (max-device-width: 767px) {
    flex-flow: row;
    justify-content: space-between;
  }
}

.social-profile-flex-row > .input-container {
  @media screen and (max-device-width: 1024px) {
    margin-right: 20px;
  }

  @media screen and (max-device-width: 767px) {
    margin-right: 0px;
  }
}

//

.social-profile-best-review-wrapper {
  display: flex;

  @media screen and (max-device-width: 767px) {
    flex-flow: column;
  }
}

.best-review-content-wrapper {
  margin-left: 50px;
  width: 65%;
  @media screen and (max-device-width: 767px) {
    width: 100%;
    margin-left: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.best-review-stars-wrapper {
  //    width: 200px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.best-review-stars-wrapper > img {
  margin-right: 2px;
}

//

.social-profile-nav-cc-logo {
  // height: 3rem;
  width: 200px;

  @media screen and (max-width: 992px) {
    margin: 15px 15px;
  }
  @media screen and (max-device-width: 767px) {
    margin: 15px 15px;
  }
}

.social-profile-call-trigger-btn {
  border: 2px solid #402224;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 24px;
  background-color: white;
  border-radius: 4px;

  &:hover {
    border: 2px solid #157ffb;
    cursor: pointer;
    color: #157ffb;

    .phone-icon {
      stroke: #157ffb;
    }
  }
}

.social-profile-contact-form-btn-wrapper {
  display: flex;
}

.social-profile-contact-form-btn-wrapper > svg {
  margin-left: 20px;
}

.social-profile-contact-form-btn-wrapper > button:nth-child(2) {
  margin-left: 20px;
}

.social-profile-contact-button > svg,
.social-profile-back-button > svg {
  margin-right: 15px;
  margin-left: 10px;
}

.social-profile-back-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-top: 20px;
  font-size: 18px;
  background: #a21022;
  border: 0px;
  width: 100%;
  height: 60px;
  border-radius: 4px;
  font-family: "DM Sans";
  font-weight: 500;
  color: #fff;
  // background: #157ffb;
  box-shadow: 0 0 4px 0 rgba(211, 185, 185, 0.99);

  background-color: white;
  border: 2px solid #402224;
  color: #402224;

  &:hover {
    border: 2px solid #157ffb;
    cursor: pointer;
    color: #157ffb;

    .phone-icon {
      stroke: #157ffb;
    }
  }

  &:focus {
    outline: none;
  }
}
//

.social-profile-contact-container {
  position: fixed;
  right: 50px;
  width: 360px;
  // box-shadow: 0 0 4px 0 rgba(211,185,185,0.99);

  background-color: white;
  border: solid 1px #157ffb;
  border-radius: 4px;
  padding: 20px;
  // margin-left: 7em;

  @media screen and (max-width: 992px) {
    display: none;
    margin-left: 0em;
  }
  @media screen and (max-device-width: 767px) {
    width: 300px;
    height: 100%;
  }
}

.social-profile-contact-bar-mobile-container {
  border-top: 1px solid #157ffb;
  display: none;
  z-index: 2;
  position: fixed;
  right: 2px;
  bottom: 1px;
  width: 100%;
  background-color: white;
  height: auto;
  padding: 15px;

  @media screen and (max-width: 1024px) {
    display: block;
  }

  @media screen and (max-width: 992px) {
    display: block;
    maxwidth: 80%;
  }
  @media screen and (max-device-width: 767px) {
    display: block;
  }
}

.social-profile-contact-bar-mobile-title-container {
  display: flex;
  flex-flow: row;
  // margin-bottom: .8em;

  strong {
    color: #157ffb;
    margin-left: 1em;
  }
}

.social-profile-contact-success-container {
  background-color: white;
  border: solid 1px white;

  display: flex;
  justify-content: center;
  height: 480px;

  @media screen and (max-device-width: 1024px) {
    border: none;
    border-radius: 0px;
    // border-top: solid 6px #09d002;
    z-index: 2;
    position: fixed;
    right: 0px;
    bottom: 1px;
    width: 100%;
    background-color: white;
    height: 100%;
  }
}

.employee-profile-view-image {
  margin-bottom: 150px;

  @media screen and (max-device-width: 767px) {
    margin-bottom: -30px;
    margin-top: -1px;
  }
}

.table-view {
  display: flex;
  justify-content: space-between;
  @media screen and (max-device-width: 992px) {
    display: block;
  }
}

.load-more-reviews-btn {
  height: 40px;
  width: 180px;
  background-color: #0b1151;
  border: none;
  border-radius: 30px;
  color: #ffffff;
  font-family: "DM Sans";
  font-weight: 700;

  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  margin-top: 30px;

  &:hover {
    transition: 0.15s;
    background-color: #0b1151;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

.reached-the-end-msg {
  font-family: "DM Sans";
  color: #3f2223;

  text-align: center;
  margin-bottom: 50px;

  @media screen and (max-device-width: 776px) {
    margin-top: 30px;
  }
}

.no-reviews-container {
  padding: 30px;
  margin-bottom: 100px;
}

.valid-reviews-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
}

.view-reviews-btn {
  border: none;
  background-color: #fff4f4;
  color: #d01e1b;
  font-family: "DM Sans";
  font-weight: 700;
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

.btn-off {
  background: rgba(237, 237, 237, 0.54);
  color: #c9b7b6;

  &:hover {
    // background-color: #FFF4F4;
    // color: #D01E1B;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}
