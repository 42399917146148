.profile__successMsg {
  justify-content: center;
  align-items: center;
}

.profile__successMsg__icon {
  width: 52px;
  height: 52px;
  margin-bottom: 30px;
}

.profile__successMsg__txt {
  margin-bottom: 30px;
}

.profile__successMsg__shareProfileBtn {
  margin-bottom: 20px;
}

.profile__successMsg__bottomTxt {
  margin-top: 20px;
}

.profile__successMsgContainer {
  border: 2px solid #000;
}
