.edu-modal-outer-container {
    display: flex;
    flex-flow: column;
}

.edu-modal-form {
    display: flex;
    flex-flow: row;
}

.edu-modal-form-container-1 {
    display: flex;
    flex-flow: column;
    margin-right: 40px;
}

.edu-modal-form-container-2 {
    display: flex;
    flex-flow: column;
}


.edu-modal-item {
    margin-bottom: 20px;
}

/*  */
