.reviews-container > .review{
    display: grid;
    grid-template-areas: " rating desc";
    grid-template-columns: 25% 75%;
    padding: 20px 0px;
}

.reviews-container > .review > .rating {
    grid-area: rating;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    height: inherit;
    padding-left:10px;

}

.reviews-container > .review > .rating > .rating-container >img {
    margin-right: 2px;
    margin-bottom: 10px;
    height:25px;
    
}

.reviews-container > .review > .desc{
    grid-area: desc;
    padding:2rem;
    
}


@media screen and (max-width:1000px){

    .reviews-container > .review{
        grid-template-areas: "rating" "desc";
        grid-template-columns: 100%;
        padding: 20px 0px;
      
    }

    .reviews-container > .review > .desc{
        padding:1rem 0.5rem;
        
    }
    
  
}


@media screen and (max-width:992px){
    .reviews-container > .review > .rating {
       
        padding-left:0px;
        padding-bottom: 10px;
    }
}