.Subscription {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  position: absolute;
  display: flex;
  flex-flow: row;
  justify-content: center;
  padding: 100px;
}

.Subscription__Outer {
  display: flex;
  flex-flow: column;
}
.subscription__noPremiumLogin__content {
  padding: 0px !important;
  width: 600px;
}

.noPremiumLogin__content__title--dashboard,
.noPremiumLogin__content__subtitle--dashboard {
  padding-right: 150px;
}
.subscription__noPremiumLogin__content__pricing--free {
  width: 400px;
  background-color: #fff !important;
  border: 2px solid #d0021b;
}

.subscription__noPremiumLogin__content__pricing--free {
  width: 400px;
  background-color: #d0021b !important;
  // border: 2px solid #d0021b;
}

.subscription__noPremiumLogin__content__pricing--premium {
  width: 400px;
  height: 450px;

  background-color: #e2bf4b !important;
  // border-radius: 0 0 6px 6px;
}

.txtColor--free {
  color: #d0021b !important;
  // margin-bottom: 10px;
}

.noPremiumLogin__content__pricing__content__btn {
  background-color: #fff;
  border-radius: 8px;
  font-size: 16px;
  margin-top: 10px;
  width: 100%;
  padding: 15px;
  border: none;
  font-family: "DM Sans";
  font-weight: 600;
  color: #d0021b;

  &:hover {
    cursor: pointer;
    opacity: 0.95;
  }
}

.noPremiumLogin__content__pricing__content--dashboard {
  margin-top: 10px;
  margin-bottom: 10px;
}

.noPremiumLogin__homeBtn {
  position: absolute;
  right: 100px;
  font-size: 18px;
  font-family: "DM Sans";

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.subscription__currentPlanTxt {
  color: #d0021b;
}
