.searchAvail__body {
  margin-top: 50px;
  margin-bottom: 100px;

  @media screen and (max-device-width: 767px) {
    margin-top: 20px;
    margin-bottom: 50px;
  }
}

.searchAvail__content__title {
  width: 604px;

  @media screen and (max-device-width: 767px) {
    width: auto;
  }
}

.searchAvail__selectButton {
  @media screen and (max-device-width: 767px) {
    // height: 182px;
    width: 285px;
    height: 70px;
    border-radius: 6px;
    // margin-bottom: 20px;

    flex-flow: row;
    justify-content: space-between;
  }
}

.searchAvail__selectButton__txtWrap {
  display: flex;
  flex-flow: column;

  @media screen and (max-device-width: 767px) {
    margin-right: 75px;
  }
}
