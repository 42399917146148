.google-places-input-wrapper {
  margin-top: 40px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  // align-items: center;
  border: 1px solid #6a7a89;

  @media screen and (max-width: 992px) {
  }

  @media screen and (max-width: 767px) {
    //    padding-left: 10px;
    //    padding-right: 10px;

    min-width: 100%;
  }
}

.location-search-input {
  padding: 15px 20px;

  min-width: 400px;
  border-radius: 4px;

  font-family: "DM Sans";
  color: #402224;
  border: none;

  &:focus {
    outline: none;
    // border: 1px solid #402224;
  }

  @media screen and (max-width: 767px) {
    min-width: 70%;
    // font-size: 90%;

    font-size: 16px;
  }
}

.google-places-results-wrapper {
  // display: flex;
  // flex-flow: column;
  position: fixed;

  width: 400px;
  color: #402224;
  line-height-step: 30px;

  @media screen and (max-width: 767px) {
    max-width: 300px;
    font-size: 90%;
  }
}

.google-places-submit-btn {
  // margin-top: 30px;
  padding: 15px 20px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  border: none;
  background-color: #157ffb;

  font-family: "DM Sans";
  color: #ffffff;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

.google-places-results {
  padding: 5px;
  border-bottom: 1px solid #deccce;
}

.powered-by-google-bar {
  background-color: #ffffff;
  // padding: 5px;
  height: 35px;
}

.powered-by-google-img {
  float: right;
  padding-top: 10px;
  padding-right: 10px;
  width: 120px;
}
