.SubNavbar {
  width: 320px;
  height: 100vh;
  background-color: #F3F3F3;
  flex-shrink: 0;
  position: fixed;
  margin-left: 234px;
  a {
    text-decoration: none;
  }

  @media screen and (max-width: 992px) {
    // width: 100px;
  }
}

.sub-navbar-container {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  background-color: #E2E2E2;
  border-radius: 8px;
  margin: 20px 10px;
}

.sub-navbar-title {
  font-family: "Roboto";
  font-weight: 300;
  font-size: 24px;
  color: #CF021A;
}

.sub-navbar-link {
  height: 82px;
  border-bottom: 1px solid #D8D8D8;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row;
  padding: 10px;

  &.active {
    border-right: 4px solid #D0021B;
  }

  &:hover {
    cursor: pointer;
    background-color: #E9E9E9;
    text-decoration: none;
  }
}

.sub-navbar-icon {
  width: 22px; 
}

.sub-navbar-icon-container {
  margin-right: 20px;
  margin-left: 10px;
}

.sub-navbar-text-container {
  display: flex;
  flex-flow: column;
}

.sub-navbar-text-title {
  font-family: "Roboto";
  font-weight: 400;
  color: #1F2023;
  font-size: 16px;
}

.sub-navbar-text-subtitle {
  font-family: "Roboto";
  color: #ADADAD;
  font-size: 14px;
  margin-bottom: 0;
}