.ClientList {
  background-color: #f6f0f0;
  display: flex;
  flex-flow: column;
  justify-content: center;

  margin-left: 230px;
  padding: 30px 30px 60px;
  width: 100%;
  height: 100%;
}

.ClientList > h1 {
  padding: 20px 0px;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 36px;
  color: #3f2222;
}

.client-list-table-header {
  display: flex;
  justify-content: space-around;
  width: 100%;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  color: #3f2222;
}

.client-list-table-container {
  display: flex;
  flex-flow: column;
  width: 100%;
  // justify-content: space-evenly;

  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;

  border-radius: 8px;

  background-color: white;
  height: 687px;
}

.client-list-table-row {
  display: flex;
  justify-content: space-around;
  width: 100%;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  color: rgb(126, 125, 125);

  padding-top: 25px;
}

.clients-add-button {
  // background: white;
  background: transparent;
  border: none;
}

.clients-add-button:focus {
  outline: none;
}

.user-plus-icon:hover {
  stroke: rgb(235, 62, 79);
  // box-shadow: 0 0 10px 0 rgba(211, 185, 185, 0.99);
  transition: 0.15s;
  cursor: pointer;
}

table {
  border-collapse: column;
}

/*header text*/
th {
  font-size: 18px;
  font-weight: 400;
  color: #3f2222;
}

/*inside text*/
td {
  font-size: 16px;
  font-weight: 300;
  color: #3f2222;
}

td:first-child {
  font-size: 16px;
  font-weight: 300;
  color: #3f2222;
}
.td-1,
.td-2,
.td-3,
.td-4,
.td-5 {
  border-radius: 10px;
  padding: 8px;
}

.td-1 {
  background-color: #cbe5ff;
  color: #0080ff;
  font-weight: 700;
}

.td-2 {
  background-color: #fef1f1;
  color: #d0011c;
  font-weight: 700;
  &:hover {
    cursor: pointer;
  }
}

.td-3 {
  background-color: rgb(255, 252, 203);
  display: flex;
  justify-content: space-between;
  color: #ffc700;
  font-weight: 700;

  &:hover {
    background-color: rgb(253, 250, 185);
  }
  // .client-phone-icon {
  //   stroke: #FFEF00;
  // }
}

.td-3-link:hover {
  text-decoration: none;
}

.td-4 {
  background-color: rgba(240, 203, 255, 0.664);
  color: #b500ff;
  font-weight: 700;
}

.td-5 {
  background-color: rgba(255, 203, 203, 0.281);
  color: rgb(235, 62, 79);
  font-weight: 700;
  display: flex;
  justify-content: space-between;
}

.td-5:hover {
  background-color: #f9e2e2;
  cursor: pointer;
  transition: 0.15s;
  .client-arrow-right-icon {
    // stroke: rgb(235, 62, 79);
    // box-shadow: 0 0 10px 0 rgba(211, 185, 185, 0.99);
    transition: 0.15s;
    cursor: pointer;
  }
}

th,
td {
  text-align: left;
}

tr:nth-child(odd) {
  background: rgb(247, 244, 244);
}

.table {
  // display: flex;
  // flex-flow: column;
  border-radius: 10px;
  overflow: hidden;
  perspective: 1px;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  white-space: nowrap;

  margin-top: 32px;
  background: #ffffff;
  box-shadow: 0 0 20px -10px rgba(115, 115, 115, 0.5);
}

.table thead th {
  border-bottom: none;
}

.table td {
  border: none;
  font-family: "Roboto";
  font-weight: 200;
  text-align: left;
  white-space: nowrap;
  // overflow: hidden;
  text-overflow: ellipsis;

  padding: 15px 20px 10px 20px;
  width: 100%;
}

/*workflow table - header*/
.table th {
  font-family: "Roboto";
  font-weight: 400;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  text-align: left;
  background-color: #fff;
  border: 0;

  width: 100%;
}

.table thead tr {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.table th:last-child,
.table td:last-child {
  text-align: right;
  padding-right: 20px;
}

.table tbody {
  max-height: calc(95vh - 225px);
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
}

.table thead {
  display: block;
  overflow-x: hidden;
}

.table tbody tr {
  display: flex;
}

.client-list-table-actions-bar {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.clients-search-field {
  // margin-top: 3em;
  margin-right: 1.5em;
  padding: 7px 20px 7px 20px;

  cursor: pointer;
  border-radius: 10px;
  font-size: 20px;
  // background-color: transparent;
  font-weight: 200;
  // border: 1px solid #953939;
  box-shadow: 0 2px 20px -10px rgba(115, 115, 115, 0.5);

  &::placeholder {
    color: rgb(168, 157, 157);
  }
}

.clients-search-field {
  color: rgb(168, 157, 157);
  background-color: #fff;
  border: none;
}

.clients-search-field:focus {
  outline: none;
}

.clients-sort-by-dropdown,
.clients-filter-by-dropdown {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 12px;

  display: flex;
  align-items: center;

  background: #ffffff;
  // border: 1px solid #C52D29;
  border-radius: 5px;
  font-family: "Roboto";
  font-size: 16px;
  width: 107px;
  height: 38px;
  color: #c52d29;
  // border: 2px solid rgba(63,34,34,0.35);
}

.clients-sort-by-dropdown:hover,
.clients-filter-by-dropdown:hover {
  cursor: pointer;
}

.clients-filter-by-dropdown {
  margin-right: 1.5em;
}

.clients-search-add-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clients-dropdown-wrap {
  display: flex;
  flex-flow: row;
}

//

.client-list-no-clients-conatiner {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.client-list-no-clients-title-wrap {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.client-list-no-clients-title {
  font-family: "Roboto";
  font-size: 32px;
  color: #402223;
}

.no-clients-frown-icon {
  margin-left: 10px;
}

.no-clients-add-btn,
.no-clients-add-btn-2 {
  margin-top: 15px;
  margin-bottom: 30px;

  width: auto;
  height: 35px;
  font-size: 18px;
}

.no-clients-add-btn-2 {
  margin-left: 25px;
}

.client-list-no-clients-btn-wrap {
  display: flex;
  flex-flow: row;
}

.client-list-table-no-clients-illustration {
  // margin-bottom: 40px;
  max-width: 260px;
}
