.profile-card-employee,
.profile-card-employee--ccPremium {
  margin-top: 2em;

  height: auto;
  max-width: 65%;
  // margin-top: 2em;
  max-height: 327px;
  min-width: 800px;
  background: #ffffff;
  border: 2px solid #cec4c4;
  border-radius: 8px;

  &:hover {
    border: 2px solid #157ffb;

    transition: 0.15s;
    box-shadow: 0 0 20px -10px rgba(115, 115, 115, 0.5);
  }

  @media screen and (max-width: 1102px) {
    width: 90%;
  }

  @media screen and (max-width: 992px) {
    min-width: 600px;

    margin-top: 3em;
    // height: 100%;
  }

  @media screen and (max-width: 767px) {
    min-width: 100%;
    height: 100%;
    width: 100%;
    border: 1px solid #cec4c4;
    border-radius: 6px;
  }
}

.profile-card-employee--ccPremium {
  &:hover {
    border: 2px solid #e8c30c;
  }
}

.profile-card-p,
.profile-card-p--ccPremium {
  &:nth-child(1) {
    margin-top: 2em;
  }

  height: auto;
  max-width: 65%;
  // margin-top: 2em;
  max-height: 327px;
  min-width: 800px;
  background: #ffffff;
  border: 2px solid #d2d2d2;
  border-radius: 8px;

  &:hover {
    border: 2px solid #157ffb;

    box-shadow: 0 0 20px -10px rgba(115, 115, 115, 0.5);
    transition: 0.15s;
    .profile-card-button-bar-conatiner {
    }

    .profile-card-button-bar-analytics-txt {
      color: #fff;
    }
  }

  @media screen and (max-width: 1102px) {
    width: 90%;
  }

  @media screen and (max-width: 992px) {
    min-width: 600px;
    // height: 100%;
  }

  @media screen and (max-width: 767px) {
    min-width: 100%;
    height: 100%;
    width: 100%;
    border: 1px solid #cec4c4;
    border-radius: 6px;

    &:nth-child(1) {
      margin-top: 3em;
    }
  }
}

.profile-card-p--ccPremium {
  &:nth-child(1) {
    margin-top: 2em;

    @media screen and (max-width: 992px) {
      margin-top: 3em;
    }
  }

  &:hover {
    border: 2px solid #e8c30c;

    transition: 0.15s;
    box-shadow: 0 0 20px -10px rgba(115, 115, 115, 0.5);
  }

  @media screen and (max-width: 992px) {
    border: 2px solid #e8c30c;
    margin-top: 50px;
  }
}

.profile-card-image {
  text-align: center;
}

.profile-card-image {
  /* width: 150px; */
  margin-left: 2em;
  margin-top: 0.5em;
  margin-bottom: 2.3em;
}

.profile-card-img-n-order-wrapper,
.profile-card-img-n-order-wrapper--ccPremium,
.profile-card-img-n-order-wrapper--ccPremiumFirm {
  display: none;
  @media screen and (max-width: 992px) {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    // padding-bottom: 20px;
  }
}

.profile-card-img-n-order-wrapper--ccPremium,
.profile-card-img-n-order-wrapper--ccPremiumFirm {
  @media screen and (max-width: 992px) {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    background-image: linear-gradient(82deg, #e2bf4b 3%, #fffbe7 96%);
    padding: 8px;
  }
}

.profile-card-img-n-order-wrapper--ccPremiumFirm {
  @media screen and (max-width: 992px) {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    background-image: linear-gradient(82deg, #e2bf4b 3%, #fffbe7 96%);
    padding: 8px;
    width: 100%;
  }
}

.profile-card-order-number,
.profile-card-order-number--ccPremium,
.profile-card-order-number--ccPremiumFirm {
  font-family: "DM Sans";
  font-weight: 700;
  color: white;
  background-color: #0b1151;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-left: -15px;
  position: absolute;

  @media screen and (max-width: 992px) {
    margin-left: 0px;
    position: relative;

    margin-top: -32px;
    height: 25px;
    width: 25px;
  }
}

.profile-card-order-number--ccPremium,
.profile-card-order-number--ccPremiumFirm {
  @media screen and (max-width: 992px) {
    background-color: #fff;
    color: #e2bf4b;
    // border: 2px solid #e2bf4b;
    margin-left: 30px;
    margin-top: -22px;
    // margin-top: 0px;
  }
  @media screen and (max-width: 768px) {
    margin-left: 5px;
  }
}

.profile-card-order-number--ccPremiumFirm {
  display: none;

  @media screen and (max-width: 992px) {
    display: flex;
  }
}

.profile-card-img-wrapper,
.profile-card-img-wrapper--ccPremium {
  margin-top: -40px;
  display: none;

  @media screen and (max-width: 992px) {
    display: flex;
  }
}

.profile-card-img-wrapper--ccPremium {
  padding-right: 30px;

  @media screen and (max-width: 768px) {
    padding-right: 7px;
  }
}

.profile-card-img {
  width: 70px;
  height: 70px;

  @media screen and (max-width: 992px) {
    width: 60px;
    height: 60px;
  }

  @media screen and (max-width: 767px) {
    width: 50px;
    height: 50px;
  }
}

.profile-card-image p {
  font-family: Open Sans;
  font-size: 16px;
  color: #9b9b9b;
  margin-top: 20px;
}

/**/

.profile-card-title {
  display: flex;
  flex-flow: column;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.profile-card-title h3 {
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 28px;
  color: #3e2222;
  margin-right: 0px;
}

// .profile-card-title p {
//   font-family: 'DM Sans';
//   font-size: 20px;
//   color: #715F5F;
// }

// .profile-card-title span {
//   text-overflow: ellipsis;
//   font-size: 16px;
//   font-weight: 300;
//   color: #715F5F;
// }

/**/
.profile-card-info-wrapper {
  display: flex;
  flex-flow: column;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-flow: row;
  }
}

.profile-card-info h4 {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 18px;
  color: #3e2222;
}

// .profile-card-info p {
//   font-family: 'Roboto';
//   font-size: 16px;
//   color: #A48C8D;
//   margin-left: 2.5em;
// }

// .profile-card-info-2 p {
//   margin-left: 2.5em;
// }

// .profile-card-title hr {
//   width: 500px;
//   @media screen and (max-width: 992px) {
//     width: 200px;
//   }
//   @media screen and (max-device-width:767px){
//     display: none;

//   }
// }

// .profile-card-button button {
//   padding: 13px 27px 13px 27px;
//   margin-left: 0em;
//   margin-right: 0em;
//   margin-top: 0em;

// }
/**/

.profile-card-button button {
  float: right;
  background-image: linear-gradient(-82deg, #157ffb 3%, #157ffb 96%);
  border-radius: 5px;
  /* box-shadow: 0 1px 6px 0 rgba(0,0,0,0.50); */
  font-family: "DM Sans";
  font-weight: 700;
  border: none;

  font-size: 14px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  padding: 13px 27px 13px 27px;

  margin-bottom: 1em;

  /* margin-left: 20em; */
  @media screen and (max-width: 992px) {
  }
  @media screen and (max-device-width: 767px) {
    margin-bottom: 0em;
  }
}

.profile-card-button button:hover {
  opacity: 0.8;
  transition: 0.15s;
}

.card-info,
.card-info--ccPremium {
  display: flex;

  // display: flex;
  flex-flow: column;
  width: 100%;
  // justify-content:space-between;
  // width: 100%;
  // margin-left: 50px;

  @media screen and (max-device-width: 767px) {
  }
}

.card-p,
.card-p--ccPremium {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  text-align: left;

  background-color: transparent;
  border: none;
  width: 100%;
  padding: 20px 35px 20px 35px;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  @media screen and (max-device-width: 992px) {
    justify-content: center;
    padding: 20px 15px 20px 15px;
  }
}

/**/

/* 
 */

.cc-trusted {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 11.5px;
  color: #f53551;
}

.fa-award {
  color: #cc2727;
}

.default-pic-background {
  background: #d8d8d8;
  margin-left: 3em;
  margin-top: 1em;
  height: 140px;
  width: 140px;
}

/* On screens that are 992px or less, set the background color to blue */
// @media screen and (max-width: 992px) {

//   .profile-card-p{
//     margin-right: 50px;
//     margin-left: 50px;
//   }

//   .profile-card-button button {
//     padding: 13px 27px 13px 27px;
//     margin-left: 12em;
//     margin-right: 0em;
//     margin-top: -3em;
//   }

// }

// MOBILE STUFF
.profile-card-info-wrapper-mobile {
  display: none;
}

.profile-card-title-mobile {
  display: none;
}

.cc-trusted-mobile {
  display: none;
}

.cc-trusted-img-mobile {
  display: none;
}

.employee-card {
  @media screen and (max-device-width: 767px) {
    height: auto;
    padding-bottom: 12%;
  }

  // iphone 5
  @media screen and (max-device-width: 360px) {
    min-height: 230px;
  }
}

@media screen and (max-device-width: 767px) {
  // MOBILE STUFF
  .cc-trusted-img-mobile {
    display: block;
  }

  .profile-card-info-wrapper-mobile {
    display: block;
  }

  .profile-card-title-mobile {
    display: block;
    font-size: 20px;
    margin-top: 1em;
    font-family: "DM Sans";
    color: #3f2223;
  }

  .profile-card-title {
    // display: none;
  }

  .profile-card-info-wrapper {
    display: none;
  }

  .profile-card-image {
    display: flex;
    flex-flow: column;
  }

  .cc-trusted-img-mobile-wrap {
    display: flex;
    flex-flow: row;
  }

  .cc-trusted-mobile {
    display: block;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 10px;
    color: #f53551;
    text-align: left;
    margin-left: 10px;
  }

  .cc-trusted-img-mobile {
    height: 25px;
    width: 25px;
  }

  .viewlaw-profile-btn-wrap {
    display: flex;
    justify-content: space-between;
    margin-right: 0em;
  }

  //
  .profile-fields {
    display: none;
  }

  .profile-card-button button {
    padding: 13px 27px 13px 27px;
    font-size: 12px;
  }

  .profile-card-button {
    margin-right: 1em;
  }

  // .cc-trusted {
  //   display: none;
  // }

  .default-pic-background {
    width: 100px;
    height: 100px;
    margin-left: 5px;
  }

  .profile-card-image {
    width: 100px;
    height: 100px;
    margin-left: 5px;
    margin-bottom: 0em;
  }

  .profile-card-image p {
    margin-top: 35px;
    margin-left: 15px;
  }

  .viewlaw-profile-btn-wrap {
    margin-top: 0.7em;
  }

  /**/

  /**/
  .profile-card-info {
    margin-right: 2em;
    margin-left: 0em;
    // display: none;
  }

  // .profile-card-button-mobile {
  //   display: block;
  //   margin-top: -.5em;
  //   margin-left: 6.5em;

  // }
}

.viewlaw-profile-btn-wrap {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

@media screen and (max-device-width: 360px) {
  .profile-card-info-2 {
    display: none;
  }

  .profile-card-button button {
    padding: 5px 10px 5px 10px;
    font-size: 12px;
    margin-right: 0.2em;
  }
}

.profile-card-info-addr-label,
.profile-card-info-language-label {
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 18px;
  color: #3f2223;
}

.profile-card-info-address-value,
.profile-card-info-language-value {
  font-family: "DM Sans";
  font-weight: 400;
  font-size: 16px;
  color: #a48c8d;
}

// EMPLOYEE CARD

.employee-card {
  width: 100%;

  .employee-card-img {
    min-width: 100px;
    min-height: 100px;
    max-width: 100px;
    max-height: 100px;
    margin-top: 1em;
  }
  .emp-card-cc-trusted-wrap {
    display: flex;
    flex-flow: row;

    .emp-card-cc-trusted-img {
      color: #f53551;
    }

    .emp-card-cc-trusted-txt {
      display: block;
      font-family: "Roboto";
      font-weight: 700;
      font-size: 10px;
      color: #f53551;
      text-align: left;
      margin-left: 10px;
    }
  }
}

.emp-card-address {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

//

.profile-card-information-container {
  margin-top: 10px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 767px) {
    flex-flow: row wrap;
  }
}

.profile-card-best-review-container {
  margin-top: 10px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 767px) {
    justify-content: center;
    display: none;
  }
}

.profile-card-header-container {
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    align-items: flex-end;
    justify-content: column-reverse;
    flex-flow: wrap-reverse;
  }
}

.profile-card-header-inner-container {
  display: flex;
  flex-flow: column;
}

.profile-card-button-bar-conatiner,
.profile-card-button-bar-conatiner--ccPremium {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  // background-image: linear-gradient(82deg, #157ffb 3%, #91c4ff 96%);
  margin-top: 20px;
  margin-bottom: -20px;
  margin-left: -35px;
  margin-right: -35px;
  border-radius: 6px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 5px;

  background-image: linear-gradient(82deg, #157ffb 3%, #91c4ff 96%);
  // border-top: 2px solid #d2d2d2;

  @media screen and (max-width: 992px) {
    display: none;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 0px;
  }
}

.profile-card-button-bar-conatiner--ccPremium {
  background-image: linear-gradient(82deg, #e2bf4b 3%, #fffbe7 96%);
}

.profile-card-bar-no-pg-rev {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 992px) {
    display: none;
  }
}

.profile-card-button-bar-analytics-txt {
  font-family: "DM Sans";
  font-weight: 700;
  color: #fff;
  margin-left: 30px;
}

.profile-view-profile-btn,
.profile-view-profile-btn--ccPremium {
  background-color: #157ffb;
  border-radius: 4px;
  font-family: "DM Sans";
  font-weight: 700;
  border: none;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  padding: 13px 27px 13px 27px;

  &:hover {
    opacity: 0.8;
    transition: 0.15s;
    outline: none;

    // background-image: linear-gradient(82deg, #157ffb 3%, #91c4ff 96%);
  }
}

.profile-view-profile-btn--ccPremium {
  background-color: #e8c30c;
}
//  firm

.profile-firm-card-img-wrapper {
  margin-top: 10px;
}

.profile-firm-card-img {
  max-width: 140px;
  max-height: 50px;
}

.hide-reviews-search-mobile {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.hide-profile-firm-card-img {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.profile-card-rating-start-img {
  width: 16px;
  height: 16px;
  margin-top: 2.5px;
  margin-right: 6px;
}

.card-p--ccPremium {
  @media screen and (max-width: 992px) {
    padding: 0px;
    padding: 0px !important;
  }
}

.card-info--ccPremium {
  @media screen and (max-width: 992px) {
    padding: 20px 35px 20px 35px;
  }
  @media screen and (max-width: 768px) {
    padding: 20px 15px 20px 15px;
  }
}

.profile-card-header-txt-conainer--ccPremium {
  @media screen and (max-width: 992px) {
    padding: 20px 35px 20px 35px;
  }
  @media screen and (max-width: 768px) {
    padding: 20px 15px 20px 15px;
  }
}
