@import '../../../style/_variables.scss';

.ClientDetails {
    background-color: #F6F0F0;
	display: flex;
	flex-flow: column;
    justify-content: center;
    
	margin-left: 230px;
	padding: 20px 40px 60px;
	width: 100%;
	height: 100%;
}

.ClientDetails > h6 {
	padding: 20px 0px 0px;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 18px;
	color: rgba(62,34,34,0.37);
}

.client-details-title-wrap {
	@include flex-row-align-center;
	
	
}


.client-details-title-wrap > h1 {
	padding: 20px 0px;
	font-family: "Roboto";
	font-weight: 700;
	font-size: 32px;
	color: #3F2222;
	margin-top: .2em;
	margin-left: 20px;
}


.client-details-arrow-back-btn:hover {
	opacity: .5;
	cursor: pointer;
}
