.search-loading-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  // align-items: center;
  height: 150vh;
  width: 100%;
}

.search-loading-header {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  background-color: #eef6ff;
  padding: 50px 50px;
  height: 40%;
  display: flex;
}

.search-loading-body {
  display: flex;
  flex-flow: column;

  align-items: center;
  text-align: center;

  background-color: #157ffb;
  // background-image: linear-gradient(180deg, #FFFFFF 0%, #E4E2E2 100%);
  padding: 50px 100px;
  height: 60%;
}

.search-loading-cc-logo {
  width: 50px;
  margin-bottom: 20px;
}

.search-loading-cc-search-gif {
  width: 100px;
  height: 100px;
}
