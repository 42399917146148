.firms-result-page-wrapper {
  display: flex;
  flex-flow: row;
  // width: 100%;
  justify-content: flex-start;

  @media screen and (max-width: 767px) {
    flex-flow: column-reverse;
  }
}

.Results {
  // height: 100vh;
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;

  @media screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.result-container {
  // display: flex;
  // flex-flow: column;
  // justify-content: center;
  // align-items: center;

  // height: 100%;
  // width: 100%;

  padding-left: 100px;
  padding-right: 100px;

  @media screen and (max-width: 992px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

// .firm-banner-svg-img-wrapper {
//     display: flex;
//     flex-flow: column;
//     justify-content: center;
// }

// .firm-banner-svg-img-wrapper > button {
//     margin-left: 30%;
//     margin-right: 30%;
//    margin-top: -60px;
//     // width: 140px;
//     padding: 10px;
//     font-family: 'DM Sans';
//     font-weight: 700;
//     color: white;
//     background-color: transparent;
//     border: 1px solid white;
//     border-radius: 30px;
//     outline: none;

//     &:hover {
//         background-color: white;
//         transition: .2s;
//         cursor: pointer;
//         color: #F53B4E;
//     }

// }

.search-banner {
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  @media screen and (max-width: 992px) {
    display: flex;
    flex-flow: column;
  }

  .search-banner-img {
    margin-top: 1em;

    @media screen and (max-width: 992px) {
      width: 100%;
    }

    @media screen and (max-width: 767px) {
      //    display: none;
    }
  }

  .search-banner-results-txt-wrap {
    width: 100%;
    display: flex;
    flex-flow: column;
    text-align: left;
    margin-left: 0em;

    @media screen and (max-width: 1024px) {
      margin-left: 2em;
    }
    @media screen and (max-width: 992px) {
      margin-left: 0em;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }

    .result-title {
      font-family: "DM Sans";
      font-size: 32px;
      font-weight: 700;
      color: #3e2222;
      text-align: left;

      @media screen and (max-width: 767px) {
        font-size: 24px;
      }
    }

    .result-subtitle {
      font-family: "DM Sans";
      font-size: 19px;
      font-weight: 400;
      color: #a38c8c;
      text-align: left;
      margin-bottom: 1.5em;
    }

    .result-paragraph {
      font-family: "DM Sans";
      font-size: 16px;
      font-weight: 400;
      color: #3e2222;
    }
  }
}

.search-results-label {
  font-family: "DM Sans";
  font-size: 18px;
  color: #3e2222;
  font-weight: 500;
  margin-left: 2em;
}

// LAW FIRMS

.law-firms-boxes-wrap {
  display: flex;
  flex-flow: column;
  width: 80%;

  @media screen and (max-width: 767px) {
    flex-flow: column;
    width: 100%;
  }
}

.mobile-hide {
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.desktop-hide {
  display: none;
  @media screen and (max-width: 992px) {
    display: block;
  }
}

.law-firm-search-img {
  width: 250px;
  margin-bottom: 2em;
  margin-top: 1em;
}

.firm-employee-list-wrapper {
  display: flex;
  flex-flow: column;
}

.firm-card-name {
  font-weight: 700;
  @media screen and (max-width: 767px) {
    font-size: 18px;
  }
}

.number-of-resulted-firms {
  margin-left: 35px;
  margin-top: 25px;

  @media screen and (max-width: 992px) {
    margin-bottom: 0px;
    font-size: 16px;
    margin-top: 0px;
    line-height: 30px;
    margin-left: 20px;
    margin-right: 15px;
    // margin-bottom: 25px;
    text-align: center;
  }
}

.number-of-resulted-firms > strong {
  border-bottom: 2.5px solid;
}

.card-dotted-line {
  // display: none;
  margin: 10px 0px;
  border: 0.5px solid #e6e0e0;
  border-style: dashed;

  @media screen and (max-width: 992px) {
    display: block;
    font-size: 14px;
  }
}

.firm-card-address,
.firm-card-avg-rating,
.firm-card-fee-txt {
  margin-right: 5px;
  @media screen and (max-width: 767px) {
    font-size: 14px;
    margin-right: 0px;
  }
}

.firm-card-rating-start-img {
  margin-right: 8px;
  margin-bottom: 2px;

  @media screen and (max-width: 767px) {
    width: 20%;
    margin-right: 8px;
  }
}

.firm-card-rating-container {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  //    margin-right: 0;
}

.firm-card-consultation-wrapper {
  display: flex;
}

.firm-card-consultation-wrapper {
  @media screen and (max-width: 992px) {
    display: flex;
    align-items: center;
    // padding: 10px;
    padding-left: 0px;
    // padding-bottom: 10px;
  }
}

.firm-card-consultation-txt {
  color: #6a7a89;
  font-weight: 700;
  font-size: 14px;
  margin-left: 10px;
}

.searchResults {
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 992px) {
    flex-flow: column;
    margin-bottom: 100px;
  }
}

.searchResults__chatgpt {
  margin-right: 50px;
  margin-left: 150px;
  width: 35%;
  margin-top: 50px;

  @media screen and (max-width: 992px) {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.searchResults__chatgpt__userMsgContainer {
  margin-top: 55px;
}

.searchResults__chatgpt__role {
  font-family: "Dm Sans";
  color: #4c2323;
  margin-left: 10px;
  font-size: 18px;
}
.searchResults__chatgpt__userMsg,
.searchResults__chatgpt__aiMsg {
  margin-top: 10px;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #fff;

  border-radius: 19px;
  padding: 32px 30px;
  border: none;
  font-size: 18px;
  font-family: "Dm Sans";
  color: #4c2323;
  outline: none;
  min-height: 50px !important;
}

.searchResults__chatgpt__aiMsg {
  background-color: #e3dbdb;
}

.searchResults__chatgpt__poweredbyIcon {
  margin-top: 16px;
  height: 25px;
}

.results__header {
  @media screen and (max-width: 768px) {
    border-bottom: 1.5px solid #fff;
  }
}

.results__sortBy__dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
  margin-right: 20px;
  @media screen and (max-width: 768px) {
    justify-content: center;
    margin-right: 0px;
    // margin-left: 10px;
    position: static;
  }
}

.results__sortBy__dropdown__toggle {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  width: 160px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 17px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.results__sortBy__dropdown__toggle__iconWrap {
  display: flex;
  align-items: center;
}
.results__sortBy__dropdown__toggle__txt {
  font-family: "Dm Sans";
  font-size: 16px;
  color: #3699ff;
  font-weight: 700;
  margin-left: 10px;
}

.results__sortBy__dropdown__options {
  border: 1px solid #d8d8d8;
  margin-top: 210px;
  z-index: 1;
  position: absolute;

  border-radius: 4px;

  background: #ffffff;
  // box-shadow: 0px 0px 20px 3px rgba(56, 71, 109, 0.15);

  @media screen and (max-device-width: 767px) {
    // position: relative;
    display: flex;
    z-index: 0 !important;
    margin-top: 0px;
  }

  li {
    list-style: none;

    /* pobup-menu-text-14 */

    font-family: "Dm Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;

    color: #3f4254;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0px;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
}

.dropdown__txtWrap {
  // padding: 10px;
  width: 160px;
}

.flex-column {
  display: flex;
  flex-flow: column;
}

.createPostModal_optionTxt {
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #3f4254;
  margin-left: 10px;
}

.dropdown__iconOptionWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
  // margin-bottom: 10px;
  border-radius: 4px;
  width: 160px;
  background-color: #fff;
  padding: 15px;
  padding-left: 13px;
  border-bottom: 1px solid #d8d8d8;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
    background-color: #f1f7ff;
    border-radius: 4px;

    .dropdown__iconOptionTxt {
      color: #3699ff;
    }

    .dropdown__iconOptionImg path {
      fill: #3699ff;
    }
  }

  &:last-child {
    margin-bottom: 0px;
  }
}

.results__toggleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-show-only {
  display: none !important;

  @media screen and (max-device-width: 767px) {
    display: block !important;
  }
}

.results__sortByTxt {
  font-family: "Dm Sans";
  font-weight: 700;
}

.results__sortByContainer {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: flex-end;
}
