.lw-portal-text-area-field-container {
    display: flex;
    flex-flow: column;
}

.lw-portal-text-area-field-title {
    font-family: "Sarabun";
    font-size: 15px;
    font-weight: 700;
    color: #362727;
    margin-left: 5px;
}

.lw-portal-text-area-field {
  outline: none;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 300;
  background: #FFFFFF;
  color: #111111;
  border-radius: 6px;
  border: 1px solid #D3B9B9;
  padding: 10px 23px;
  width: 440px;
  height: 100px;
}

.lw-portal-text-area-field:focus {
    border: 1px solid #3689FF;
}


.lw-portal-text-area-field-info {
    font-size: 13px;
    font-family: "Roboto";
    font-weight: 400;
    color: #9b9b9b;
    margin-top: 10px;
    margin-left: 5px;
}

/* MODAL TEXT AREA */
.modal-text-area-field-container {
    display: flex;
    flex-flow: column;
}

.modal-text-area-field-title {
    font-family: "Sarabun";
    font-size: 15px;
    font-weight: 700;
    color: #362727;
    margin-left: 5px;
}

.modal-text-area-field {
    outline: none;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 300;
    background: #FFFFFF;
    color: #111111;
    border-radius: 6px;
    border: 1px solid #D3B9B9;
    padding: 10px 10px;
    width: 300px;
    height: 100px;
}

.modal-text-area-field:focus {
    border: 1px solid #3689FF;
}


.modal-text-area-field-info {
    font-size: 13px;
    font-family: "Roboto";
    font-weight: 400;
    color: #c2c2c2;
    margin-top: 10px;
    margin-left: 5px;
}

/* create profile */

.create-profile-text-area-field {
    outline: none;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 300;
    background: #FFFFFF;
    color: #111111;
    border-radius: 6px;
    border: 1px solid #D3B9B9;
    padding: 10px 23px;
    width: 600px;
    height: 100px;
}


/* edit profile - textarea */
.edit-profile-text-area-input {
    outline: none;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 300;
    background: #FFFFFF;
    color: #111111;
    border-radius: 6px;
    border: 1px solid #D3B9B9;
    padding: 10px 23px;
    width: 500px;
    height: 100px;
}



@media screen and (max-width: 1180px) {
    .lw-portal-text-area-field {
        width: 250px;
    }
}
