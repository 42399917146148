.fallback-profile-card-img-container {
    background-color: #F6F0F0;
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 100%;
    // width: 100%;

    .fallback-profile-card-img-usr-icon {
        // size: 42px;
    }
}



